


























































import { Vue, Component } from "vue-property-decorator";
import { regionData } from "element-china-area-data";
@Component
export default class shop extends Vue {
    public tabIndex = 0;
    public options = regionData;
    public selectedOptions = [];
    public keywords = "";

    public handleChange(value: any) {}

    public metaInfo = {
        title: "联系我们-简单睡眠",
        meta: [
            {
                name: "简单睡眠总部，联系我们",
                content: "联系简单睡眠",
            },
        ],
    };
}
